import styled from "styled-components";

export const H = styled.h1`
  font-size: 1.8em;
  font-weight: 900;
  @media (max-width: 700px) {
  font-size: 1.5em;
  }
`;
export const HG = styled(H)`
  color: rgb(49,145,59);
`;
export const HB = styled(H)`
  color: rgba(5,7,9,.9);
`;

export const H2 = styled.h1`
  font-size: 1.3em;
  font-weight: 900;
  color: rgb(5,7,9);
  @media (max-width: 700px) {
    font-size: 1.2em;
  }  
`;


export const TextBox = styled.h1`
  margin: 1em 0;
  font-size: 1em;
  font-weight: 400;
  max-width: 32em;
`;

export const TextBoxBlack = styled(TextBox)`
  color: rgb(5,7,9);
`;
export const TextBoxGreen = styled(TextBox)`
  color: rgba(29,105,39, 0.9);
`;


