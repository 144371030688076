import React from 'react';
import {H, H2, HG, TextBox, TextBoxBlack} from '../styles/Text';
import {Box, Flex} from "@chakra-ui/core/dist";
import bud from "../assets/images/pc-bud.jpg"
import {ImageSS} from "../styles/Images";
import styled from "styled-components";

const Faq = styled.div`
  font-weight: 900;
  margin: 1.5em 0 -0.5em 0;
  font-size: 1.5em;
  color: black;
`;
const Plans: React.FC = () => {

    return (
        <div>
            <Flex p={[1, 10]}/>
            <H>
                Professional Grow Management
            </H>

            <TextBox>
                We offer a fresh solution crafted right here in the emerald triangle at the most affordable rate in the
                industry. Cruise through track and trace With many time saving features like bulk harvesting and super
                easy multi-harvest package creation.
            </TextBox>
            <Flex p={[1, 4]}/>
            <Flex justifyContent={"flex-end"}>
                <Box maxWidth="80%" margin="2em 0">
                    <ImageSS src={bud}/>
                </Box>
            </Flex>


            <Flex p={[1, 20]}/>
            <HG>
                CannaTrace is the simple solution for Cannabis Companies of all sizes, from small growers to large
                multilevel organizations.
            </HG>
            <TextBoxBlack>
                Featuring realtime Cannabis Analytics for your site with Strain Averages and Planting Averages
                including pounds per square foot and pounds per plant.
            </TextBoxBlack>
            <Flex p={[1, 10]}/>
            {/*<H center>
            <Icon weed large>
              <FontAwesomeIcon icon={faWeed}/>
            </Icon>{" "}
            Pricing
          </H>*/}
            <HG>
                No B.S. Pricing!
            </HG>
            <HG>
                $150 / Month per license.
            </HG>
            <Faq>F.A.Q.</Faq>
            <TextBoxBlack>
                Q: How much is a cultivation subscription?<br/>
                A: Its $150.<br/>
                Q: Well how much is a nursery subscription?<br/>
                A: $150 Bucks.<br/>
                Q: OK but what about Distribution?<br/>
                A: Yep still $150<br/>
            </TextBoxBlack>


            <Flex p={[1, 10]}/>
            <Flex justifyContent={"center"}>
                <H2>
                    Unlimited user accounts on every plan. <br />
                    For a custom enterprise solution contact us.
                </H2>
            </Flex>
            <Flex p={[1, 10]}/>
        </div>
    );
};

export default Plans;


