import React from 'react';
import styled from "styled-components";

import * as queryString from "querystring";
import {Flex, Grid} from "@chakra-ui/core/dist";
import {Link, Route, Switch} from "react-router-dom";
import swoopy from "../assets/images/swoop.svg";

import Home from "../pages/Home";
import Plans from '../pages/Plans';
import About from '../pages/About';
import Invest from '../pages/Invest';

const Container = styled.div`
  width: 100%;  
  min-height: 100vh;  
  background-color: rgb(238,239,240);
  background-image: url(${swoopy});
  background-size: 100%;
  background-repeat: no-repeat;
  //background-position: top center;
  //padding: 0 1.72em 0 1.72em;
  letter-spacing: 0.03em;  
 @media (max-width: 700px) {
   //background-size: 200%;
   //background-position: top center;   
   //background: radial-gradient(circle, rgba(55,164,158,1) 0%, rgba(106,179,72,1) 100%);
  background-image: linear-gradient(168deg, rgba(55,164,158,1) 30px, rgba(106,179,72,1) 420px, rgba(252,253,255,1) 700px);
  
  //background-image: url(${swoopy});
  //background-size: cover;
  }
`;

const Wrapper = styled.div`  
  margin: auto;
  max-width: 72em;
  padding: 1em 1em;
  color: white;
   @media (max-width: 700px) {
    //max-width: 100%;
   
    padding: 1em 1em;
  }
`;

const Column = styled.div`  
  
  
  
  //padding: 1em 1.72em;
 
`;

const Main = styled.main`
  //margin: 1em 0 1em 0;
`;

const Brand = styled(Link)`
  font-size: 1.8em;
  font-weight: 900;
  
`;
const BrandLight = styled.span`
  font-weight: 200;
`;
const Options = styled(Grid)`
  grid-template-columns: repeat(6,max-content);
  grid-gap: 1em;
  @media (max-width: 700px) {
    grid-gap: 0.5em;
  }
`;

const Option = styled(Link)`

  
`;

const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;


interface Params {
  site: String,
  company: String,
}

const Layout: React.FC = () => {
  let params: Params = {site: "", company: ""};

  let parsed = queryString.parse(window.location.search);
  if (parsed["site"]) {
    params.site = parsed["site"] as String
  }
  if (parsed["company"]) {
    params.company = parsed["company"] as String
  }

  //const bgColor = {light: "rgb(238,239,240)", dark: "rgb(238,239,240)"};
  //const color = {light: "rgb(7,12,16)", dark: "gray.400"};
  //fontSize={[14, 14, 14, 15, 17]}
  // bg={bgColor['light']} color={color['light']}
  return (
    <Container>
      <Wrapper>
        <Column>
          <Header>
            <Brand to={"/"}><BrandLight>Canna</BrandLight>Trace</Brand>
            <Options>
              <Option to={"/"}>
                Home
              </Option>
              <Option to={"/plans"}>
                Plans
              </Option>
              <Option to={"/about"}>
                About Us
              </Option>
            {/*  <Option to={"/invest"}>
                Invest
              </Option>*/}
              <a href={"https://app.cannatrace.net/register"}>
                Register
              </a>
              <a href={"https://app.cannatrace.net/login"}>
                Login
              </a>
            </Options>
          </Header>
          <Main>
            <Switch>
              <Route path={"/invest"}>
                <Invest/>
              </Route>
              <Route path={"/about"}>
                <About/>
              </Route>
              <Route path={"/plans"}>
                <Plans/>
              </Route>
              <Route path={"/"}>
                <Home/>
              </Route>
            </Switch>
          </Main>
        </Column>
      </Wrapper>
    </Container>
  )
};

export default Layout;