import React from 'react';
import {H, TextBox} from '../styles/Text';
import {Box, Flex} from "@chakra-ui/core/dist";
//import {ImageSS} from "../styles/Images";
//import vista from "../assets/images/vista.jpg";


const About: React.FC = () => {
  return (
    <div>
      <Flex p={[1, 10]}/>
      <H>
        Brought to you by Little Lake Studios.
      </H>
      <TextBox>
        CannaTrace is developed in Mendocino County from the grass up by farmers for farmers to make Cultivation
        planning and production straight forward and record-keeping easy.
      </TextBox>
      <Flex p={[1, 10]}/>
      <Flex justifyContent={"flex-end"}>
        <Box maxWidth="80%" margin="2em 0">
         {/* <ImageSS src={vista}/>*/}
        </Box>
      </Flex>
      <Flex p={[1, 10,10,20]}/>
      <H>
        <a href="https://www.littlelakestudios.com">www.littlelakestudios.com</a>
      </H>
      <H>
        707-456-7131
      </H>
      <H>
        <a href="mailto:info@cannatrace.net">info@cannatrace.net</a>
      </H>
      <Flex p={[4, 20]}/>
    </div>
  );
};

export default About;


