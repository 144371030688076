import React from 'react';
import {H, HB, HG, TextBox, TextBoxGreen} from '../styles/Text';
import {Box, Flex, Grid, Image} from "@chakra-ui/core/dist";
import styled from 'styled-components';
import cal from "../assets/images/cal.png"
import tasks from "../assets/images/tasks.png"
import {ReactComponent as CannaButton} from "../assets/images/cannaButton.svg";
//import ddr from "../assets/images/ddr.png";
import pc from "../assets/images/pacCult.png";
import threeBrothers from "../assets/images/ThreeBros11.png";
import snm from "../assets/images/snm.png";
import mendo from "../assets/images/mendo.png";
import waska from "../assets/images/WaskaTag.png";
import strongMnt from "../assets/images/strongMnt.png"
import {ImageSS} from "../styles/Images";

const Double = styled(Grid)`
  grid-template-columns: minmax(min-content, 40em) max-content;
  grid-gap: 1em;
  margin: 2em 0;
  align-items: center;
  @media(max-width: 700px) {
    grid-template-columns: minmax(min-content, 18em) max-content;
  }
`;


const Logos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;  
  margin: 0 0 1em 0;
  flex-wrap: wrap;
`;

const Logo = styled.div`
  //margin: 1em;
  margin: auto;
  max-width: 15em;
  
  @media (max-width: 1200px) {
    max-width: 224px;
  }
`;


const Home: React.FC = () => {

  return (
    <div>
      <Flex p={[1, 10]}/>
      <H>The New Standard in Cannabis Team Management</H>
      <TextBox>
        Created in the heart of the Emerald Triangle as a full featured Track and Trace system, available on Mobile &
        Desktop. CannaTrace is the most advanced Metrc integrated Seed to Sale team management app, with a colorful Grow
        Calendar and integrated Task Management system.
      </TextBox>
      <Flex p={[1, 5]}/>
      <Flex justifyContent={"flex-end"}>
        <Box maxWidth="80%" margin="2em 0">
          <ImageSS src={cal}/>
        </Box>
      </Flex>
      <Flex p={[2, 4, 5, 6, 20, 30]}/>
      <Flex p={[0, 0, 0, 0, 10, 30]}/>
      <Double>
        <HG>The professional platform for Cannabis Management, Production and Track & Trace.
          With integrated Task Management</HG>
        <CannaButton width={"70px"}/>
      </Double>
      <TextBoxGreen>
        The central place for all your team members to collaborate and get it done.
        CannaTrace is your platform for everything Cannabis.
        Lean your workflow with CannaTrace Project Management
        With the integrated task system you can assign tasks for anyone on your team for any resource in your site.
      </TextBoxGreen>
      <Flex p={[1, 5]}/>
      <Flex justifyContent={"flex-end"}>
        <Box maxWidth="80%" margin="2em 0">
          <ImageSS src={tasks}/>
        </Box>
      </Flex>
      <Flex p={[1, 4, 5, 6, 8, 20]}/>
      <Flex p={[1, 4, 5, 6, 8, 20]}/>
      <Flex justifyContent="center">
        <HB>A few of the companies we are stoked to be working with</HB>
      </Flex>
      <Logos>
        <Logo style={{padding: "6em 0.72em 5em 0.72em"}}>
          <Image src={pc}/>
        </Logo>
        <Logo style={{}}>
          <Image src={mendo}/>
        </Logo>
            <Logo style={{}}>
          <Image src={waska}/>
        </Logo>
        {/*<Logo>
          <Image src={ddr}/>
        </Logo>*/}
        <Logo style={{}}>
          <Image src={threeBrothers}/>
        </Logo>
        <Logo style={{padding: "1em"}}>
          <Image src={snm}/>
        </Logo>
        <Logo style={{padding: "2em"}}>
          <Image src={strongMnt}/>
        </Logo>
      </Logos>
      <Flex p={[4, 10]}/>
    </div>
  );
};

export default Home;


