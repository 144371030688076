import React from 'react';
import {CSSReset, theme, ThemeProvider} from "@chakra-ui/core";
import Layout from './layouts/Layout';
import {BrowserRouter} from "react-router-dom";

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
  },

  /*fonts: {
   body: "Open Sans, system-ui, sans-serif",
 },*/
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0.025em",
    wide: "0.035em",
    wider: "0.05em",
    widest: "0.2em",
  },
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset/>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
