import React from 'react';
import {H, HB, TextBox} from '../styles/Text';
import {Flex} from "@chakra-ui/core/dist";


const Invest: React.FC = () => {

  return (
    <div>
      <Flex p={[1, 10]}/>
      <H>
        Are you interested in investing in Cannabis Technology.
      </H>
      <TextBox>We are really excited about the CannaTrace platform we are developing.
        Our tech stack includes Golang and React JS and enables us to build and adapt efficiently to this developing
        industry.
        Our plans for 2020 include launching an industry-only Nursery Marketplace in the spring.</TextBox>

      <TextBox>
        Come in on the ground floor to support the building of the next phase of CannaTrace.
        <br/>
        We are looking for some small Seed Investment. If you would like to talk about
        possibilities for investment drop us a line.
      </TextBox>
      {/* <Image src={YoungPlants} />*/}
      <Flex p={[1, 10, 20]}/>
      <HB>
        707-327-2948
      </HB>
      <HB>
        <a href="mailto:info@cannatrace.net">info@cannatrace.net</a>
      </HB>
      <Flex p={[4, 20]}/>
    </div>
  );
};

export default Invest;
